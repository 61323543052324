.__page {
    position: fixed;
    top: 10vh;
    width: 100vw;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__certificate {
    width: 50%;
}

.__download {
    position: fixed;
    right: 20px;
    top: 20px;
    background-color: #ffdd97;
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #404040;
    padding: 10px 20px;
    outline: none;
    border: none;
    text-decoration: none;
}