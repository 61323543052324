.super-big-text {
    margin-top: -300px;
    font-size: 320px;
    font-weight: 900;
    text-align: center;
}

.semi-big-text {
    font-size: 36px;
    font-weight: 500;
    margin-top: -300px;
    margin-bottom: 20px;
    text-align: center;
}

.error-404-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-full-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.error-404-btn {
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 30px;
    padding: 20px 70px;
    background-color: #E8BF89;
}

@media only screen and (max-width: 900px) {
    .super-big-text {
        margin-left: -20px;
        font-size: 120px;
        margin-bottom: 30px;
    }

    .mobile-super-big-text {
        font-size: 80px !important;
        margin-left: 0 auto !important;
        text-align: center !important;
    }

    .mobile-semi-big-text {
        font-size: 30px !important;
    }

    .error-404-section {
        margin-top: -120px;
        padding: 0px 20px;
        width: 100vw !important;
    }
}